<!--
 * @Date: 2022-06-12 11:20:09
 * @LastEditTime: 2022-06-12 13:05:01
 * @Description: 
-->
<template>
  <div class="content flex_cc">
    <img alt="Newton" src="./assets/newton.png" style="width: 300px" />
  </div>
  <div>
    <img alt="signatureNewton" src="./assets/signatureNewton.png" style="width: 128px; height: 40px" /> 
    <div class="flex_cc">
      <span>艾萨克·牛顿</span> 
    </div>
    <div class="flex_cc"> 
      <span>1643/01/04 － 1727/03/31</span>
    </div>
  </div>

  <div class="bottom_50">
    <div class="flex_cc"></div>
  </div>

  <div class="bottom_10">
    <div class="flex_cc">
      <a class="beian" href="https://beian.miit.gov.cn">鲁ICP备2022018646号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

.content {
  width: 100%;
  height: 70vh;
}

.flex_cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom_50 {
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  right: 0;
}

.bottom_10 {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
  right: 0;
}

.beian {
  color: #808080;
  text-decoration: none;
}

.beian:hover{
  color: #0960bd; 
}
</style>
